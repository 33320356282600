<template>
  <div
    v-height="$vuetify.breakpoint.smAndDown ? 56 : 64"
    v-width="'100%'"
    v-position.absolute
    class="primary"
  >
    <v-app-bar
      app
      color="primary"
      dark
      flat
      hide-on-scroll
    >
      <v-row
        v-width.max="'960px'"
        align="center"
        justify="space-between"
        class="mx-auto"
        no-gutters
      >
        <v-col
          class="shrink"
        >
          <v-img
            src="/img/logos/logo-BarbaraAraujo-white-initials.png"
            alt="Bárbara de Araújo"
            title="Bárbara de Araújo"
            :height="$vuetify.breakpoint.smAndDown ? 32 : 38"
            width="55"
            contain
            rel="preload"
            as="image"
          />
        </v-col>

        <v-col
          class="grow"
        >
          <v-row
            align="center"
            justify="end"
          >
            <!-- <v-col
              class="shrink hidden-sm-and-down"
            >
              <v-menu
                bottom
                :offset-y="true"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    aria-label="Sobre LGPD"
                    title="Sobre LGPD"
                    text
                    class="text-none font-weight-light"
                    v-on="on"
                  >
                    <span
                      class="white--text"
                      v-text="'Sobre LGPD'"
                    />
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        v-ripple="false"
                        aria-label="O que Muda?"
                        title="'Oque Muda?"
                        text
                        class="text-none font-weight-light"
                      >
                        <span
                          class="primary--text"
                          v-text="'O que Muda?'"
                        />
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        v-ripple="false"
                        aria-label="O que a empresa deve fazer?"
                        title="'O que a empresadeve fazer?"
                        text
                        class="text-none font-weight-light"
                      >
                        <span
                          class="primary--text"
                          v-text="'O que a empresa deve fazer?'"
                        />
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-title>
                      <v-btn
                        v-ripple="false"
                        aria-label="Tire suas dúvidas"
                        title="Tire suas dúvidas"
                        text
                        class="text-none font-weight-light"
                      >
                        <span
                          class="primary--text"
                          v-text="'Tire suas dúvidas'"
                        />
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col> -->

            <v-col
              class="shrink"
            >
              <v-btn
                aria-label="Saiba mais sobre a LGPD"
                title="Saiba mais sobre a LGPD"
                class="text-none font-weight-light"
                text
                href="https://app.coplie.com"
                target="_blank"
                rel="noopener"
              >
                <span
                  class="white--text"
                  v-text="'Denuncie aqui'"
                />
              </v-btn>
            </v-col>

            <v-col
              class="shrink hidden-sm-and-down"
            >
              <v-menu
                bottom
                :offset-y="true"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    aria-label="Publicações"
                    title="Publicações"
                    text
                    class="text-none font-weight-light"
                    v-on="on"
                  >
                    <span
                      class="white--text"
                      v-text="'Publicações'"
                    />
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="publication in allPublications"
                    :key="`publication-${publication.title}`"
                  >
                    <v-list-item-title>
                      <v-btn
                        :aria-label="publication.title"
                        :title="publication.title"
                        text
                        :href="publication.guid"
                        target="_blank"
                        rel="noopener"
                        class="text-none font-weight-light"
                      >
                        <span
                          class="primary--text"
                          v-html="publication.title"
                        />
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script lang="ts">
  import { Component } from "vue-property-decorator"
  import { mixins } from "vue-class-component"
  import Axios from "axios"
  @Component
  export default class ComponentToolbar extends mixins() {
    ENV = (env?: string): string|boolean => window.env(env)
    globalInformations = require("@/data/global/GlobalInformations.json")
    allPublications = []

    getPublications ():void {
      Axios.get(`https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40${process.env.VUE_APP_MEDIUM_USERNAME}`).then((publicationsResponse) => {
        this.allPublications = publicationsResponse.data.items
      })
    }

    async created ():Promise<void> {
      this.getPublications()
    }
  }
</script>
