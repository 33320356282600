import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import pt from "vuetify/src/locale/pt"
import "./directives"
Vue.use(Vuetify)
export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#041f60",
        secondary: "#db5879",
        accent: "#4be4ff",
        grey: "#808080",
        gradientSecondary: "#a225c7",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
  icons: {
    iconfont: "md",
  },
})
