<template>
  <v-footer
    dark
    color="primary darken-3 py-10"
  >
    <v-row
      v-width.max="$vuetify.breakpoint.smAndDown ? 434 : (960 - 24)"
      class="mx-auto"
      no-gutters
      justify="center"
    >
      <v-col
        cols="11"
        md="12"
      >
        <v-row
          no-gutters
        >
          <v-col
            md="6"
          >
            <v-row
              class="fill-height"
              align="center"
            >
              <v-col
                cols="12"
              >
                <small
                  v-font-size="14"
                  class="font-weight-light"
                  v-text="'Contato:'"
                />
                <a
                  v-font-size="14"
                  class="d-block white--text text-decoration-none font-weight-bold"
                  :href="`tel:${String(globalInformations.telefone.atendimento || '').replace(/\W/g, '')}`"
                  rel="noopener"
                  v-text="globalInformations.telefone.atendimento"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            class="py-6"
          >
            <v-divider
              class="white"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
  import { Component } from "vue-property-decorator"
  import { mixins } from "vue-class-component"
  @Component
  export default class ComponentFooter extends mixins() {
    globalInformations = require("@/data/global/GlobalInformations.json")
    footerBadgeLinks = [
      {
        url: `https://transparencyreport.google.com/safe-browsing/search?url=${location.hostname}`,
        image: "Google%20Site%20Seguro.png",
        texto: "Google Site Seguro",
        color: "white",
      },
    ]
  }
</script>
