<template>
  <v-app>
    <component-tolbar app />

    <v-main>
      <v-container
        class="pa-0"
        fluid
      >
        <router-view />
      </v-container>
    </v-main>

    <component-footer app />
  </v-app>
</template>

<script lang="ts">
  import Vue from "vue"
  import ComponentTolbar from "@/components/toolbar/ComponentToolbar.vue"
  import ComponentFooter from "@/components/footer/ComponentFooter.vue"
  export default Vue.extend({
    name: "App",
    components: {
      ComponentTolbar,
      ComponentFooter,
    },
  })
</script>
